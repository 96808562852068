const lxSize = "lx";
const largeSize = "l";
const mediumSize = "m";
const smallSize = "s";

const CardBuilder = ({size}) => {
    switch(size){
        case lxSize:
            // TODO : return extra large size card
        case largeSize:
            // TODO : return large size card
        case mediumSize:
            // TODO : return medium size card
        case smallSize:
            // TODO : return small size card
        default:
            // TODO : return Large size card
    }
}

export {CardBuilder, lxSize, largeSize, mediumSize, smallSize};